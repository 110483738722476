.bam--title--blog {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #343434;
}
.bam--div--category {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2vh;
}
.bam--latest--blog {
  margin-bottom: 60px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 32px;

  text-align: center;
  letter-spacing: -0.02em;
  color: #343434;
}
.bam--category--button {
  width: 86px;
  height: 48px;
  margin-right: 20px;
  background: #ffffff;
  border: 1px solid #343434;
  box-sizing: border-box;
  border-radius: 28px;
  &:focus {
    background: #f87d6b;
    border-radius: 28px;
    color: #fff;
    border: 1px solid transparent;
  }
}
.bam--blog--image {
  width: 30vh;
  height: 30vh;
  border-radius: 10px;
  @media (max-width: 768px) {
    width: 20vh;
  }
}
.bam--container-blog {
  max-width: 100%;
  max-height: 25vh;
  overflow: hidden;
}

.bam--container-blog img {
  max-width: initial; /* Maybe optional. See note below */
  max-height: initial;
}
.bam--title--article {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-style: italic;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #343434;
  cursor: pointer;
}
.bam--content--article {
  margin-top: 1vh;
}
.bam--read--more--button {
  background: #52c99f;
  border-radius: 6px;
}
.bam--read--more--text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}
.bam--creator--article {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-top: 18px;
  color: #343434;
}
.bam--other--article--img {
  border-radius: 10px;
  width: 100%;
  height: 30vh;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 20vh;
  }
}
.bam--other--article--title {
  margin-top: 1vh;
  /* Subtitle Helvetica B 20 */
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #343434;
}
.bam--other--article--content {
  margin-top: 10px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 20px;

  color: #767676;
}
.bam--other--article--read--more {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 20px;
  margin-top: 10px;
  color: #4ba48e;
}
.bam--blog--welcome--title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #343434;
}
.bam--blog--welcome--subTitle {
  font-family: Helvetica;
  font-style: normal;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #343434;
}
.bam--title-slider {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #ffffff;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bam--blog--header {
  width: 100%;
  background: #f5f5f5;
  border-radius: 0px;
}
.bam--image--slider {
  border-radius: 10px;
}

.bam--date--article {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  bottom: 0;
  left: 0;

  color: #767676;
}

.bam--date-article {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #767676;
}

.bam--article-title {
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 60px;
  /* or 100% */

  letter-spacing: -0.02em;

  /* Black black */

  color: #343434;
}

.bam--title-modal {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  /* or 100% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;

  color: #000000;
}
.bam--paiement-select {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;

  /* Black black */

  color: #343434;
}
.bam--paiement-radio {
  font-style: normal !important;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  text-align: center;

  /* Black black */

  color: #343434;
}
.bam--paiement-price {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #343434;
  cursor: pointer;
}
.bam--paiement-price-second {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #343434;
  cursor: pointer;
}
