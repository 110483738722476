.bam--input--entreprise {
  background: #ffffff;

  border-radius: 6px;

  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: 40%;
  line-height: 16px;
  color: #767676;
}
.bam--card-entrprise {
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.12);
  border-radius: 18px;
}

.ant-switch .ant-switch-inner {
  color: #fff !important;
  font-size: 1em;
  font-weight: bold;
}
.ant-switch-checked .ant-switch-inner {
  color: #fff !important;
  font-size: 1em;
  font-weight: bold;
}
.bam--agenda-item-name {
  font-weight: bold;
  color: #f87d6b;
  font-size: 14px;
  margin-left: 5px;
}
.dragDiv {
  width: 100% !important;
}
.bam--select-form
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 48px !important;
  padding: 6px 14px;
  border: 1px solid #767676 !important;
  border-radius: 6px !important;
}
.bam--select-form .ant-select-selection-search-input {
  height: 48px !important;
}
.DraftEditor-editorContainer {
  background: #ffffff;
  border: 1px solid #767676;
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 5vh;
  padding: 5px;
}
