.bam--modal-big {
  border-radius: 18px !important;
}
.bam--modal-big .ant-modal-body {
  padding: 0px !important;
}
.bam--input {
  background: #ffffff;
  border: 1px solid #767676;
  box-sizing: border-box;
  border-radius: 6px;
  height: 48px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #767676;
}
.bam--input-number {
  border-radius: 6px;
}
.bam--input-number .ant-input-number-input {
  background: #ffffff;
  border: 1px solid #767676;
  box-sizing: border-box;
  border-radius: 6px;
  height: 48px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #767676;
}
.bam--label-form {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  line-height: 20px;
  color: #343434;
}
.bam--button-carrousel {
  background-color: #52c99f;
  border-radius: 6px;
  height: 40px;
  width: 40px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.bam--button-carrousel-text {
  background-color: #52c99f;
  border-radius: 6px;
  height: 40px;
  padding: 1vh;
  min-width: 12vh;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 14px;
    height: 30px;
    line-height: 10px;
  }
}
.bam--button-danger {
  background-color: #ff4d4f;
  border-radius: 6px;
  height: 40px;
  padding: 1vh;
  min-width: 12vh;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 14px;
    height: 30px;
    line-height: 10px;
  }
}
.bam--button-logout {
  background-color: #ff4d4f;
  border-radius: 6px;
  height: 40px;
  padding: 1vh;
  min-width: 12vh;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 14px;
    height: 30px;
    line-height: 10px;
  }
}
.bam--dot {
  background: #c4c4c4;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 2vh;
}

.bam--dot-active {
  border-radius: 50%;
  background: #52c99f;
  margin-right: 2vh;

  height: 8px;
  width: 8px;
}
.slick-slide img {
  display: initial;
}
.ant-radio-inner::after {
  background-color: #4ba48e !important;
}
.ant-radio-wrapper span {
  font-family: Helvetica;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #343434 !important;
}
.bam--carroussel-item {
  height: 100%;
  flex: 1;
}
.bam--img-becamepro {
  border-radius: 0px 18px 0px 0px;
}
.bam--hr-became {
  border: 1px solid #e1e1e1;
}
.bam--text-time {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #343434;
}
.bam--profile-time {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 2vh;
  text-align: center;
  color: #343434;
}
.bam--profile-price {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: right;

  /* Black black */

  color: #343434;
}
.bam--profile-select {
  background: #ffffff;
  /* Black black */

  border: 1px solid #343434;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  min-height: 5vh;
  min-width: 18vh !important;

  color: #343434;
  @media (max-width: 900px) {
    max-width: 5vh !important;
    min-width: 15vh !important;

    font-size: 12px;
  }
}
.bam--marker-map {
  background-image: url("../Styles/images/marker.svg");
  color: #fff;
  text-align: center;
  background-repeat: no-repeat;
}
.bam--pro-background {
  background: rgba(253, 172, 151, 0.7);
}
.bam--content {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @media (max-width: 1100px) {
    width: 100%;
  }
}
.bam--pro-title1 {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 60px;
  color: #343434;
  text-align: center;
  margin-left: 5px;

  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  @media (max-width: 900px) {
    font-size: 1.2rem;
    margin-left: 10px;
    line-height: 20px;
  }
}
.bam--pro-title2 {
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2rem;

  letter-spacing: -0.02em;
  color: #343434;
  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);

  margin-left: 5px;
  text-align: center;
  @media (max-width: 900px) {
    font-size: 1rem;
    margin-left: 10px;
  }
}
.bam--pro-description {
  font-style: normal;
  font-weight: 400;
  font-size: 1.35rem;
  line-height: 1.25rem;
  color: #343434;
  margin-left: 5px;
  text-align: center;
  @media (max-width: 900px) {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 1rem;
  }
}
.bam--pro-bt {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  letter-spacing: -0.01em;
  height: 48px;
  color: #ffffff;
  margin-left: 5px;
  background: #f87d6b;
  border-radius: 6px;
}
.bam--pro-border-width {
  width: 80%;
  margin-left: 20%;
}
