.bam--slider {
  background-image: url("./images/slider.svg");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  height: 50vh;
  width: 100%;
  background-position: bottom right;
  background-color: #23af7e;

  @media (max-width: 900px) {
    background-image: url("./images/illu.svg");

    height: 90vh;
    background-size: contain;
  }
}
.bam-img-search {
  cursor: pointer;
  margin-top: 5px;

  @media (max-width: 900px) {
    margin-top: 2vh;
  }
}
.bam--text-footer {
  font-size: 1rem;
  color: $bam--primary-color;
  font-family: Helvetica;
}

.bam--parles {
  background-image: url("./images/bam-parles.svg");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  padding-bottom: 3vh;
  width: 100%;
}
.bam--slider-title {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 3.5rem;
  color: #ffffff;
  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 2rem;
  }
}
.bam--section-title-first {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
}
.bam--section-title {
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: -0.02em;
  color: $bam--color-menu-item;
}

.bam--title-comment {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.3rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: $bam--color-menu-item;
  margin-top: 2vh;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1rem;
  }
}

.bam--description-comment {
  font-family: Helvetica;

  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 1vh;
  color: $bam--color-menu-item;
  min-height: 2.5rem;
  @media (max-width: 768px) {
    min-height: 4rem;
  }
}
.bam--text {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  margin-top: 1vh;
  line-height: 1.2rem;
  letter-spacing: -0.02em;
  color: $bam--color-menu-item;
  font-family: "Helvetica";
}
.bam--card-comment {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 18px !important;
}
.bam--title-services {
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5px;
  //  text-align: center;
  letter-spacing: -0.02em;
  color: #343434;
}
.bam--copyright {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #767676;
  position: absolute;
  bottom: 0;
  margin-left: 6vh;
  @media (max-width: 768px) {
    position: unset;
    bottom: 0;
    margin-left: 0vh;
    margin-top: 1vh;
  }
}
.bam--copyright-img {
  @media (max-width: 768px) {
    width: 30vh;
  }
}
.bam--title-footer {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  cursor: pointer;

  color: #767676;
}
.bam--sub-title-footer {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  cursor: pointer;
  color: #767676;
}
.bam--mini-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  cursor: pointer;
  color: #767676;
}
.bam--card-home {
  border: 1px solid #aeb5b3;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: #ffffff;
}
.bam--card-home .ant-card-body {
  padding: 10px 24px 5px 24px;
}
.bam--reservation-title {
  font-family: Helvetica;

  font-style: normal;
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 14px;
  letter-spacing: 0.04em;

  color: #343434;
  padding: 4px 11px 4px;
}
.bam--reservation-description {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 16px;
  letter-spacing: 0.04em;

  color: #767676;
}
.bam--border-right {
  border-right: 1px solid #aeb5b3;
  @media (max-width: 768px) {
    border: 0px;
  }
}
.bam--home-left-margin {
  margin-left: 3vh;
  @media (max-width: 768px) {
    margin-left: 0vh;
  }
}
.bam--cgu-article {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
}
.lst-kix_list_2-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_2-7 > li:before {
  content: "o  ";
}
ul.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li:before {
  content: "o  ";
}
.lst-kix_list_2-5 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_2-8 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_3-0 > li:before {
  content: "-  ";
}
.lst-kix_list_3-1 > li:before {
  content: "o  ";
}
.lst-kix_list_3-2 > li:before {
  content: "\0025aa  ";
}
ul.lst-kix_list_3-7 {
  list-style-type: none;
}
ul.lst-kix_list_3-8 {
  list-style-type: none;
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
ul.lst-kix_list_3-1 {
  list-style-type: none;
}
.lst-kix_list_3-5 > li:before {
  content: "\0025aa  ";
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
ul.lst-kix_list_3-2 {
  list-style-type: none;
}
ul.lst-kix_list_1-1 {
  list-style-type: none;
}
.lst-kix_list_3-4 > li:before {
  content: "o  ";
}
ul.lst-kix_list_1-2 {
  list-style-type: none;
}
ul.lst-kix_list_3-0 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_list_3-5 {
  list-style-type: none;
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ul.lst-kix_list_3-6 {
  list-style-type: none;
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
ul.lst-kix_list_3-3 {
  list-style-type: none;
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
ul.lst-kix_list_3-4 {
  list-style-type: none;
}
.lst-kix_list_3-8 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_3-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_3-7 > li:before {
  content: "o  ";
}
ul.lst-kix_list_2-8 {
  list-style-type: none;
}
ul.lst-kix_list_2-2 {
  list-style-type: none;
}
.lst-kix_list_1-0 > li:before {
  content: "\002b9a  ";
}
ul.lst-kix_list_2-3 {
  list-style-type: none;
}
ul.lst-kix_list_2-0 {
  list-style-type: none;
}
ul.lst-kix_list_2-1 {
  list-style-type: none;
}
ul.lst-kix_list_2-6 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: "o  ";
}
.lst-kix_list_1-2 > li:before {
  content: "\0025aa  ";
}
ul.lst-kix_list_2-7 {
  list-style-type: none;
}
ul.lst-kix_list_2-4 {
  list-style-type: none;
}
ul.lst-kix_list_2-5 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_1-4 > li:before {
  content: "o  ";
}
.lst-kix_list_1-7 > li:before {
  content: "o  ";
}
.lst-kix_list_1-5 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_1-6 > li:before {
  content: "\0025cf  ";
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_2-0 > li:before {
  content: "\002022  ";
}
.lst-kix_list_2-1 > li:before {
  content: "o  ";
}
.lst-kix_list_1-8 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_2-2 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_2-3 > li:before {
  content: "\0025cf  ";
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c7 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  font-weight: 700;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 12pt;
  font-family: Helvetica;
  font-style: normal;
}
.c5 {
  margin-left: 53.4pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c12 {
  margin-left: 88.8pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c8 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c6 {
  padding-top: 0pt;
  text-indent: 35.4pt;
  padding-bottom: 8pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c9 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-family: Helvetica;
  font-style: normal;
}
.c3 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16px;
  font-family: Helvetica;
  font-style: normal;
}
.c0 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16px;
  font-family: Helvetica;
  font-style: normal;
}
.c11 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c1 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c4 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c18 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #0563c1;
  text-decoration: underline;
}
.c14 {
  background-color: #ffffff;
  padding: 5pt 20pt 20pt 20pt;
}
.c13 {
  margin-left: 88.8pt;
  padding-left: 0pt;
}
.c15 {
  margin-left: 36pt;
  padding-left: 0pt;
}
.c10 {
  padding: 0;
  margin: 0;
}
.c17 {
}
.c2 {
  margin-left: 35.4pt;
}
.c16 {
  height: 16px;
}
.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 16px;
  font-family: Helvetica;
}
p {
  margin: 0;
  color: #000000;
  font-size: 16px;
  font-family: Helvetica;
}
h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 16px;
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 2pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.lst2-kix_list_5-1 > li {
  counter-increment: lst-ctn-kix_list_5-1;
}
ul.lst2-kix_list_1-0 {
  list-style-type: none;
}
.lst2-kix_list_3-0 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_3-1 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_3-2 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}
.lst2-kix_list_5-0 > li {
  counter-increment: lst-ctn-kix_list_5-0;
}
ul.lst2-kix_list_1-3 {
  list-style-type: none;
}
.lst2-kix_list_3-5 > li:before {
  content: "\0025cf  ";
}
ul.lst2-kix_list_1-4 {
  list-style-type: none;
}
ul.lst2-kix_list_1-1 {
  list-style-type: none;
}
.lst2-kix_list_3-4 > li:before {
  content: "\0025cf  ";
}
ul.lst2-kix_list_1-2 {
  list-style-type: none;
}
ul.lst2-kix_list_1-7 {
  list-style-type: none;
}
.lst2-kix_list_3-3 > li:before {
  content: "\0025cf  ";
}
ul.lst2-kix_list_1-8 {
  list-style-type: none;
}
ul.lst2-kix_list_1-5 {
  list-style-type: none;
}
ul.lst2-kix_list_1-6 {
  list-style-type: none;
}
.lst2-kix_list_3-8 > li:before {
  content: "\0025cf  ";
}
ol.lst2-kix_list_5-3.start {
  counter-reset: lst-ctn-kix_list_5-3 0;
}
.lst2-kix_list_3-6 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_4-3 > li {
  counter-increment: lst-ctn-kix_list_4-3;
}
.lst2-kix_list_3-7 > li:before {
  content: "\0025cf  ";
}
ol.lst2-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}
ol.lst2-kix_list_5-0.start {
  counter-reset: lst-ctn-kix_list_5-0 0;
}
.lst2-kix_list_5-2 > li {
  counter-increment: lst-ctn-kix_list_5-2;
}
ol.lst2-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}
.lst2-kix_list_5-0 > li:before {
  content: "" counter(lst-ctn-kix_list_5-0, decimal) ". ";
}
.lst2-kix_list_5-4 > li {
  counter-increment: lst-ctn-kix_list_5-4;
}
.lst2-kix_list_4-4 > li {
  counter-increment: lst-ctn-kix_list_4-4;
}
.lst2-kix_list_4-8 > li:before {
  content: "" counter(lst-ctn-kix_list_4-8, decimal) ". ";
}
.lst2-kix_list_5-3 > li:before {
  content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
}
.lst2-kix_list_4-7 > li:before {
  content: "" counter(lst-ctn-kix_list_4-7, decimal) ". ";
}
.lst2-kix_list_5-2 > li:before {
  content: "" counter(lst-ctn-kix_list_5-2, decimal) ". ";
}
.lst2-kix_list_5-1 > li:before {
  content: "" counter(lst-ctn-kix_list_5-1, decimal) ". ";
}
.lst2-kix_list_5-7 > li:before {
  content: "" counter(lst-ctn-kix_list_5-7, decimal) ". ";
}
ol.lst2-kix_list_5-6.start {
  counter-reset: lst-ctn-kix_list_5-6 0;
}
.lst2-kix_list_5-6 > li:before {
  content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
}
.lst2-kix_list_5-8 > li:before {
  content: "" counter(lst-ctn-kix_list_5-8, decimal) ". ";
}
ol.lst2-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}
ul.lst2-kix_list_4-1 {
  list-style-type: none;
}
.lst2-kix_list_5-4 > li:before {
  content: "" counter(lst-ctn-kix_list_5-4, decimal) ". ";
}
.lst2-kix_list_5-5 > li:before {
  content: "" counter(lst-ctn-kix_list_5-5, decimal) ". ";
}
.lst2-kix_list_6-1 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_6-3 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_6-0 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_6-4 > li:before {
  content: "\0025cf  ";
}
ol.lst2-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}
.lst2-kix_list_6-2 > li:before {
  content: "\0025cf  ";
}
ol.lst2-kix_list_5-5.start {
  counter-reset: lst-ctn-kix_list_5-5 0;
}
.lst2-kix_list_6-8 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_6-5 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_6-7 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_7-0 > li:before {
  content: "\0025cf  ";
}
ol.lst2-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}
.lst2-kix_list_6-6 > li:before {
  content: "\0025cf  ";
}
ol.lst2-kix_list_5-0 {
  list-style-type: none;
}
.lst2-kix_list_2-6 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_2-7 > li:before {
  content: "\0025cf  ";
}
ol.lst2-kix_list_5-1 {
  list-style-type: none;
}
ol.lst2-kix_list_5-2 {
  list-style-type: none;
}
.lst2-kix_list_7-4 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_7-6 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_2-4 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_2-5 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_2-8 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_7-1 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_7-5 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_7-2 > li:before {
  content: "\0025cf  ";
}
ol.lst2-kix_list_5-4.start {
  counter-reset: lst-ctn-kix_list_5-4 0;
}
.lst2-kix_list_7-3 > li:before {
  content: "\0025cf  ";
}
ul.lst2-kix_list_7-5 {
  list-style-type: none;
}
ul.lst2-kix_list_7-6 {
  list-style-type: none;
}
ul.lst2-kix_list_7-3 {
  list-style-type: none;
}
ul.lst2-kix_list_3-7 {
  list-style-type: none;
}
ul.lst2-kix_list_7-4 {
  list-style-type: none;
}
ul.lst2-kix_list_3-8 {
  list-style-type: none;
}
ol.lst2-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}
ol.lst2-kix_list_5-1.start {
  counter-reset: lst-ctn-kix_list_5-1 0;
}
ul.lst2-kix_list_7-7 {
  list-style-type: none;
}
ul.lst2-kix_list_7-8 {
  list-style-type: none;
}
ol.lst2-kix_list_5-7 {
  list-style-type: none;
}
ul.lst2-kix_list_3-1 {
  list-style-type: none;
}
ol.lst2-kix_list_5-8 {
  list-style-type: none;
}
ul.lst2-kix_list_3-2 {
  list-style-type: none;
}
.lst2-kix_list_5-7 > li {
  counter-increment: lst-ctn-kix_list_5-7;
}
.lst2-kix_list_7-8 > li:before {
  content: "\0025cf  ";
}
ul.lst2-kix_list_3-0 {
  list-style-type: none;
}
ol.lst2-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}
ol.lst2-kix_list_5-3 {
  list-style-type: none;
}
ul.lst2-kix_list_7-1 {
  list-style-type: none;
}
ul.lst2-kix_list_3-5 {
  list-style-type: none;
}
.lst2-kix_list_4-7 > li {
  counter-increment: lst-ctn-kix_list_4-7;
}
ol.lst2-kix_list_5-4 {
  list-style-type: none;
}
ul.lst2-kix_list_7-2 {
  list-style-type: none;
}
ul.lst2-kix_list_3-6 {
  list-style-type: none;
}
ol.lst2-kix_list_5-5 {
  list-style-type: none;
}
ul.lst2-kix_list_3-3 {
  list-style-type: none;
}
ol.lst2-kix_list_5-6 {
  list-style-type: none;
}
ul.lst2-kix_list_7-0 {
  list-style-type: none;
}
.lst2-kix_list_7-7 > li:before {
  content: "\0025cf  ";
}
ul.lst2-kix_list_3-4 {
  list-style-type: none;
}
.lst2-kix_list_5-8 > li {
  counter-increment: lst-ctn-kix_list_5-8;
}
.lst2-kix_list_4-0 > li:before {
  content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
}
.lst2-kix_list_4-1 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_4-6 > li {
  counter-increment: lst-ctn-kix_list_4-6;
}
.lst2-kix_list_4-4 > li:before {
  content: "" counter(lst-ctn-kix_list_4-4, decimal) ". ";
}
.lst2-kix_list_4-3 > li:before {
  content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
}
.lst2-kix_list_4-5 > li:before {
  content: "" counter(lst-ctn-kix_list_4-5, decimal) ". ";
}
.lst2-kix_list_4-2 > li:before {
  content: "" counter(lst-ctn-kix_list_4-2, decimal) ". ";
}
.lst2-kix_list_4-6 > li:before {
  content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
}
ol.lst2-kix_list_5-7.start {
  counter-reset: lst-ctn-kix_list_5-7 0;
}
.lst2-kix_list_5-5 > li {
  counter-increment: lst-ctn-kix_list_5-5;
}
ol.lst2-kix_list_4-0 {
  list-style-type: none;
}
ol.lst2-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}
ol.lst2-kix_list_4-2 {
  list-style-type: none;
}
ol.lst2-kix_list_4-3 {
  list-style-type: none;
}
ul.lst2-kix_list_6-6 {
  list-style-type: none;
}
ul.lst2-kix_list_6-7 {
  list-style-type: none;
}
.lst2-kix_list_5-3 > li {
  counter-increment: lst-ctn-kix_list_5-3;
}
ul.lst2-kix_list_6-4 {
  list-style-type: none;
}
ul.lst2-kix_list_2-8 {
  list-style-type: none;
}
ul.lst2-kix_list_6-5 {
  list-style-type: none;
}
ul.lst2-kix_list_6-8 {
  list-style-type: none;
}
ol.lst2-kix_list_4-8 {
  list-style-type: none;
}
ul.lst2-kix_list_2-2 {
  list-style-type: none;
}
.lst2-kix_list_1-0 > li:before {
  content: "\0025cf  ";
}
ul.lst2-kix_list_2-3 {
  list-style-type: none;
}
ul.lst2-kix_list_2-0 {
  list-style-type: none;
}
ul.lst2-kix_list_2-1 {
  list-style-type: none;
}
ol.lst2-kix_list_4-4 {
  list-style-type: none;
}
ul.lst2-kix_list_6-2 {
  list-style-type: none;
}
ul.lst2-kix_list_2-6 {
  list-style-type: none;
}
ol.lst2-kix_list_4-5 {
  list-style-type: none;
}
ul.lst2-kix_list_6-3 {
  list-style-type: none;
}
.lst2-kix_list_1-1 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_1-2 > li:before {
  content: "\0025cf  ";
}
ul.lst2-kix_list_2-7 {
  list-style-type: none;
}
ol.lst2-kix_list_4-6 {
  list-style-type: none;
}
ul.lst2-kix_list_6-0 {
  list-style-type: none;
}
ul.lst2-kix_list_2-4 {
  list-style-type: none;
}
ol.lst2-kix_list_4-7 {
  list-style-type: none;
}
ul.lst2-kix_list_6-1 {
  list-style-type: none;
}
ul.lst2-kix_list_2-5 {
  list-style-type: none;
}
.lst2-kix_list_1-3 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_1-4 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_4-8 > li {
  counter-increment: lst-ctn-kix_list_4-8;
}
.lst2-kix_list_1-7 > li:before {
  content: "\0025cf  ";
}
ol.lst2-kix_list_5-8.start {
  counter-reset: lst-ctn-kix_list_5-8 0;
}
.lst2-kix_list_1-5 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_1-6 > li:before {
  content: "\0025cf  ";
}
li.li2-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst2-kix_list_5-6 > li {
  counter-increment: lst-ctn-kix_list_5-6;
}
.lst2-kix_list_2-0 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_2-1 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_4-5 > li {
  counter-increment: lst-ctn-kix_list_4-5;
}
.lst2-kix_list_1-8 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_2-2 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_2-3 > li:before {
  content: "\0025cf  ";
}
.lst2-kix_list_4-2 > li {
  counter-increment: lst-ctn-kix_list_4-2;
}
ol.lst2-kix_list_5-2.start {
  counter-reset: lst-ctn-kix_list_5-2 0;
}
ol {
  margin: 0;
  padding: 0;
}

.x21 {
  background-color: #ffffff;
  margin-left: 0pt;
  padding-top: 8.6pt;
  padding-left: 0pt;
  padding-bottom: 8.6pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.x11 {
  background-color: #ffffff;
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.x6 {
  background-color: #ffffff;
  margin-left: 36pt;
  padding-top: 8.6pt;
  padding-left: 0pt;
  padding-bottom: 8.6pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.x15 {
  background-color: #ffffff;
  margin-left: 0pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c8 {
  background-color: #ffffff;
  margin-left: 0pt;
  padding-top: 8.6pt;
  padding-left: 0pt;
  padding-bottom: 8.6pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.x10 {
  background-color: #ffffff;
  margin-left: 0pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.x3 {
  background-color: #ffffff;
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 16px;
}
.x28 {
  background-color: #ffffff;
  padding-top: 8.6pt;
  padding-bottom: 8.6pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 16px;
}
.x5 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 16px;
}
.c9 {
  background-color: #ffffff;
  padding-bottom: 8.6pt;
  margin-top: 2vh;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 16px;
}
.x24 {
  -webkit-text-decoration-skip: none;
  color: #0563x1;
  font-weight: 400;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 16px;
  font-family: "Helvetica Neue";
}
.x25 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 16px;
}
.x27 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.x7 {
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  font-weight: 400;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 16px;
  font-family: "Helvetica Neue";
}
.x12 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.x16 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.x1 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16px;
  font-family: "Helvetica Neue";
  font-style: normal;
}
.x18 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.x4 {
  font-size: 16px;
  font-family: "Helvetica Neue";

  font-weight: 700;
}
.x22 {
  font-size: 11.5pt;
  font-family: "Helvetica Neue";

  font-weight: 400;
}
.x2 {
  font-size: 16px;
  font-family: "Helvetica Neue";

  font-weight: 400;
}
.x23 {
  font-size: 16px;
  font-family: "PT Sans";

  font-weight: 400;
}
.x14 {
  font-weight: 400;
  font-size: 16px;
  font-family: "Helvetica";
}
.x26 {
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  font-family: "Helvetica Neue";
}
.x17 {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  font-family: "Helvetica Neue";
}
.x19 {
  background-color: #ffffff;
  max-width: 453.6pt;
  padding: 70.8pt 70.8pt 70.8pt 70.8pt;
}
.x13 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.x20 {
  color: inherit;
  text-decoration: inherit;
}
.c0 {
  padding: 0;
  margin: 0;
}
.x30 {
  height: 16px;
}
.x29 {
  margin-left: 72pt;
}
.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 20px;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 16px;
  font-family: "Helvetica";
}
// p {
//   margin: 0;
//   color: #000000;
//   font-size: 16px;
//   font-family: "Helvetica";
// }
h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 16px;
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 2pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.bam--home-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #343434;
  @media (max-width: 900px) {
    font-size: 25px;
  }
}
