.bam--avis-img {
  width: 62px;
  height: 62px;
  margin-right: 2vh;
}
.bam--avis-name {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  display: "block";
  /* identical to box height */

  /* Black black */

  color: #343434;
}
.bam--avis-date {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;

  /* Grey Dark */

  color: #767676;
}
.bam--avis-descrption {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-top: 3vh;

  color: #343434;
}
