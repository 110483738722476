.bam--menu-item {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  text-align: right;
  letter-spacing: -0.02em;
  color: $bam--color-menu-item !important;
  &:hover {
    color: #f87d6b !important;
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent !important;
}

.bam--menu-button-pro {
  background: $bam--primary-color !important;
  border-radius: 6px !important;
  color: #fff !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 1rem !important;
  line-height: 20px !important;
  text-align: center !important;
  letter-spacing: -0.01em;
  color: #ffffff;
  @media (max-width: 900px) {
    font-size: 0.75rem !important;
    line-height: 10px !important;
    padding: 10px !important;
    text-align: left !important;
    margin-left: 2vh;
  }
}
.bam--copyright-div {
  @media (max-width: 900px) {
    display: grid;
  }
}
.bam--menu-connect {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  text-align: right;
  letter-spacing: -0.02em;
  color: $bam--primary-color;
  cursor: pointer;
}
