.bam--profile-name {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;

  letter-spacing: -0.02em;
  color: #343434;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
  }
}
.bam--profile-rate {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  display: inline-flex;
  letter-spacing: -0.02em;

  color: #f7b003;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 5px;
  }
}
.bam--profile-rate img {
  padding-top: -20px;
  @media (max-width: 768px) {
    height: 15px;
  }
}
.bam--horaire {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  /* or 107% */

  letter-spacing: -0.02em;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 25px;
  }
}
.bam--profile-nb-rate {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #4ba48e;
  vertical-align: text-bottom;
  @media (max-width: 768px) {
    font-size: 10px;
    vertical-align: bottom;
  }
}
.bam--no-border-bottom {
  border-bottom: 1px transparent solid !important;
}
.bam--border-bottom {
  border-bottom: 1px solid #aeb5b3;
}
.bam--like-text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #4ba48e;
}
.bam--profile-tabs .ant-tabs-tab-active {
  background: #c6f0e6;
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;

  color: #4ba48e !important;
}

.bam--profile-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  min-width: 20vh;
  color: #343434;
  text-align: left;
  @media (max-width: 768px) {
    min-width: 12vh;
  }
}
.bam--service-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #343434;
}
.bam--profile-address {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #4ba48e;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}
.bam--avis-text-bold {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */

  /* Black black */

  color: #343434;
}
.bam--nb-results {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #767676;
  @media (max-width: 768px) {
    margin-left: 5px;
  }
}
.bam--margin-profil {
  margin-top: 4vh;
  @media (max-width: 900px) {
    margin-top: 0vh;
  }
}
.bam--margin-avis {
  margin-top: 0.5vh;
  @media (max-width: 900px) {
    margin-top: 0.5vh;
  }
}
.bam--modal-profil .ant-modal-header {
  border-radius: 18px 18px 0px 0px;
}
.ant-input-wrapper .ant-input-group {
  height: 54px;

  box-sizing: border-box;
  border-radius: 28px;
}
ul.bam--ul-text {
  list-style-type: circle;
}

.bam--hide-text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */

  text-align: right;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  margin-right: 2vh;

  color: #767676;
}
.bam--img-results {
  height: 100%;
  width: 100%;
  border-radius: 10px 0px 0px 10px;
  @media (min-width: 1080px) {
    max-height: 320px;
  }
}
.bam--results-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;

  text-transform: capitalize;

  color: #343434;
}
.bam--results-description {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;

  color: #767676;
}
.bam--results-price {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #767676;
}
.circle-red:before {
  content: " \25CF";
  font-size: 15px;
  color: red;
}
.circle-green:before {
  content: " \25CF";
  font-size: 15px;
  color: #4ba48e;
}
.bam--search-input {
  border-radius: 28px;
  min-height: 50px;
  width: 100%;
  border: 1px solid #aeb5b3;
  box-sizing: border-box;
}

.bam--search-icon {
  content: url("./images/filter.svg");
  margin-top: 5px;
  border-left: 1px solid rgba(174, 181, 179, 0.4);
}
.bam--button-choice {
  height: 30px;
  background-color: #aeb5b3;
  border-radius: 28px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  margin-right: 2vh;

  color: #ffffff;
}
.bam--item-result {
  padding: 2vh 0vh 2vh 0vh;
  border-bottom: 1px solid #e5e5e5;
}
.bam--item-result-parent {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-top: 2vh;
}
.bam--item-result-parent:hover {
  box-shadow: 0px 4px 30px rgba(43, 43, 43, 0.13);
  cursor: pointer;
}
#rounded div {
  background-color: transparent !important;
}
.bam--profile-images-item {
  width: 100%;
}

.bam--container-button {
  position: absolute;
  bottom: 10px;
  right: 10px;

  background: #ffffff;
  /* Black black */
  height: 48px;

  border: 1px solid #343434;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;

  /* Black black */

  color: #343434;
}
.bam--container-img {
  max-width: 100%;
  max-height: 154px;
  overflow: hidden;
}

.bam--container-img img {
  max-width: initial; /* Maybe optional. See note below */
  max-height: initial;
}
.bam--img-star {
  width: 10px;
}
#slider .slick-dots {
  bottom: 0px !important;
}

.bam--espace-pro-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  /* or 107% */

  letter-spacing: -0.02em;

  color: #000000;
}
