.bam--input--entreprise {
  background: #ffffff;

  border-radius: 6px;

  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: 40%;
  line-height: 16px;
  color: #767676;
}
.bam--card {
  /* White White */
  height: 187px;
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.12);
  border-radius: 18px;
  .ant-card-body {
    text-align: center;
    padding: 0px 24px 24px 0px;
  }
}

.bam--title {
  font-family: Helvetica;
  font-style: normal;
  //  font-weight: normal;
  font-size: 1.75rem;
  font-weight: bold;
}
.bam--profile {
  border-radius: 6px;
  border-color: #f68c75;
  border-style: solid;
}
.bam--text--button {
  color: red;
  font-family: Helvetica;
  font-style: normal;
  font-size: 1rem;
}
.bam--text--validation {
  font-family: Helvetica;
  font-style: normal;
  color: white;
  font-size: 1.25rem;
}
.bam--text--card--colored {
  font-family: Helvetica;
  font-style: normal;

  font-size: 1rem;
  text-align: center;
  width: 100%;
}
.bam--card--number {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: -0.02em;

  /* Black black */

  color: #343434;
}
.bam--card--title {
  /* Body Helvetica Reg 16 */

  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  /* Black black */

  color: #343434;
}
.bam--text--card {
  font-family: Helvetica;
  font-style: normal;
  font-size: 1rem;
  text-align: center;
  width: 100%;
}
.bam--title--entreprise {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-left: 20px;
  /* Black black */

  color: #343434;
}
.bam--adress--entreprise {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.02em;
}
.ant-anchor-link-title:only-child {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.02em;
  text-decoration-line: underline;

  color: #32b788;
}
.bam--link--profileEntreprise {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.02em;
  text-decoration-line: underline;

  color: #32b788;
}
