.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.bam--title--blog {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #343434; }

.bam--div--category {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2vh; }

.bam--latest--blog {
  margin-bottom: 60px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #343434; }

.bam--category--button {
  width: 86px;
  height: 48px;
  margin-right: 20px;
  background: #ffffff;
  border: 1px solid #343434;
  box-sizing: border-box;
  border-radius: 28px; }
  .bam--category--button:focus {
    background: #f87d6b;
    border-radius: 28px;
    color: #fff;
    border: 1px solid transparent; }

.bam--blog--image {
  width: 30vh;
  height: 30vh;
  border-radius: 10px; }
  @media (max-width: 768px) {
    .bam--blog--image {
      width: 20vh; } }

.bam--container-blog {
  max-width: 100%;
  max-height: 25vh;
  overflow: hidden; }

.bam--container-blog img {
  max-width: none;
  max-width: initial;
  /* Maybe optional. See note below */
  max-height: none;
  max-height: initial; }

.bam--title--article {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-style: italic;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #343434;
  cursor: pointer; }

.bam--content--article {
  margin-top: 1vh; }

.bam--read--more--button {
  background: #52c99f;
  border-radius: 6px; }

.bam--read--more--text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff; }

.bam--creator--article {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-top: 18px;
  color: #343434; }

.bam--other--article--img {
  border-radius: 10px;
  width: 100%;
  height: 30vh;
  border-radius: 10px; }
  @media (max-width: 768px) {
    .bam--other--article--img {
      width: 20vh; } }

.bam--other--article--title {
  margin-top: 1vh;
  /* Subtitle Helvetica B 20 */
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #343434; }

.bam--other--article--content {
  margin-top: 10px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 20px;
  color: #767676; }

.bam--other--article--read--more {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 20px;
  margin-top: 10px;
  color: #4ba48e; }

.bam--blog--welcome--title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #343434; }

.bam--blog--welcome--subTitle {
  font-family: Helvetica;
  font-style: normal;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #343434; }

.bam--title-slider {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #ffffff;
  position: absolute;
  top: 80%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.bam--blog--header {
  width: 100%;
  background: #f5f5f5;
  border-radius: 0px; }

.bam--image--slider {
  border-radius: 10px; }

.bam--date--article {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #767676; }

.bam--date-article {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #767676; }

.bam--article-title {
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 60px;
  /* or 100% */
  letter-spacing: -0.02em;
  /* Black black */
  color: #343434; }

.bam--title-modal {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  /* or 100% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000; }

.bam--paiement-select {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  /* Black black */
  color: #343434; }

.bam--paiement-radio {
  font-style: normal !important;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  /* Black black */
  color: #343434; }

.bam--paiement-price {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #343434;
  cursor: pointer; }

.bam--paiement-price-second {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #343434;
  cursor: pointer; }

.bam--left--layout {
  background: #4ba48e; }

.bam--header--pro {
  padding: 0;
  background-color: red !important; }

.bam--left--layout .ant-menu-item {
  font-family: Helvetica Neue;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 44px !important;
  letter-spacing: 0.02em;
  color: #ffffff; }

.bam--left--layout li.ant-menu-item.ant-menu-item-selected {
  font-family: Helvetica Neue;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 44px !important;
  letter-spacing: 0.02em;
  color: #ffffff !important;
  background: rgba(29, 64, 56, 0.6) !important;
  opacity: 0.8;
  border-radius: 4px; }

.bam--input--entreprise {
  background: #ffffff;
  border-radius: 6px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: 40%;
  line-height: 16px;
  color: #767676; }

.bam--card-entrprise {
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.12);
  border-radius: 18px; }

.ant-switch .ant-switch-inner {
  color: #fff !important;
  font-size: 1em;
  font-weight: bold; }

.ant-switch-checked .ant-switch-inner {
  color: #fff !important;
  font-size: 1em;
  font-weight: bold; }

.bam--agenda-item-name {
  font-weight: bold;
  color: #f87d6b;
  font-size: 14px;
  margin-left: 5px; }

.dragDiv {
  width: 100% !important; }

.bam--select-form
.ant-select:not(.ant-select-customize-input)
.ant-select-selector {
  height: 48px !important;
  padding: 6px 14px;
  border: 1px solid #767676 !important;
  border-radius: 6px !important; }

.bam--select-form .ant-select-selection-search-input {
  height: 48px !important; }

.DraftEditor-editorContainer {
  background: #ffffff;
  border: 1px solid #767676;
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 5vh;
  padding: 5px; }

.bam--input--entreprise {
  background: #ffffff;
  border-radius: 6px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: 40%;
  line-height: 16px;
  color: #767676; }

.bam--card {
  /* White White */
  height: 187px;
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.12);
  border-radius: 18px; }
  .bam--card .ant-card-body {
    text-align: center;
    padding: 0px 24px 24px 0px; }

.bam--title {
  font-family: Helvetica;
  font-style: normal;
  font-size: 1.75rem;
  font-weight: bold; }

.bam--profile {
  border-radius: 6px;
  border-color: #f68c75;
  border-style: solid; }

.bam--text--button {
  color: red;
  font-family: Helvetica;
  font-style: normal;
  font-size: 1rem; }

.bam--text--validation {
  font-family: Helvetica;
  font-style: normal;
  color: white;
  font-size: 1.25rem; }

.bam--text--card--colored {
  font-family: Helvetica;
  font-style: normal;
  font-size: 1rem;
  text-align: center;
  width: 100%; }

.bam--card--number {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: -0.02em;
  /* Black black */
  color: #343434; }

.bam--card--title {
  /* Body Helvetica Reg 16 */
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  /* Black black */
  color: #343434; }

.bam--text--card {
  font-family: Helvetica;
  font-style: normal;
  font-size: 1rem;
  text-align: center;
  width: 100%; }

.bam--title--entreprise {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-left: 20px;
  /* Black black */
  color: #343434; }

.bam--adress--entreprise {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.02em; }

.ant-anchor-link-title:only-child {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.02em;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #32b788; }

.bam--link--profileEntreprise {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.02em;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #32b788; }

.bam--menu-item {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  text-align: right;
  letter-spacing: -0.02em;
  color: #343434 !important; }
  .bam--menu-item:hover {
    color: #f87d6b !important; }

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent !important; }

.bam--menu-button-pro {
  background: #f87d6b !important;
  border-radius: 6px !important;
  color: #fff !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 1rem !important;
  line-height: 20px !important;
  text-align: center !important;
  letter-spacing: -0.01em;
  color: #ffffff; }
  @media (max-width: 900px) {
    .bam--menu-button-pro {
      font-size: 0.75rem !important;
      line-height: 10px !important;
      padding: 10px !important;
      text-align: left !important;
      margin-left: 2vh; } }

@media (max-width: 900px) {
  .bam--copyright-div {
    display: grid; } }

.bam--menu-connect {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  text-align: right;
  letter-spacing: -0.02em;
  color: #f87d6b;
  cursor: pointer; }

.bam--slider {
  background-image: url(/static/media/slider.120d26e3.svg);
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  height: 50vh;
  width: 100%;
  background-position: bottom right;
  background-color: #23af7e; }
  @media (max-width: 900px) {
    .bam--slider {
      background-image: url(/static/media/illu.b2165d48.svg);
      height: 90vh;
      background-size: contain; } }

.bam-img-search {
  cursor: pointer;
  margin-top: 5px; }
  @media (max-width: 900px) {
    .bam-img-search {
      margin-top: 2vh; } }

.bam--text-footer {
  font-size: 1rem;
  color: #f87d6b;
  font-family: Helvetica; }

.bam--parles {
  background-image: url(/static/media/bam-parles.143abbc1.svg);
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  padding-bottom: 3vh;
  width: 100%; }

.bam--slider-title {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 3.5rem;
  color: #ffffff;
  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08); }
  @media (max-width: 768px) {
    .bam--slider-title {
      font-size: 2rem;
      line-height: 2rem; } }

.bam--section-title-first {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem; }

.bam--section-title {
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: -0.02em;
  color: #343434; }

.bam--title-comment {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.3rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: #343434;
  margin-top: 2vh; }
  @media (max-width: 768px) {
    .bam--title-comment {
      font-size: 1rem;
      line-height: 1rem; } }

.bam--description-comment {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 1vh;
  color: #343434;
  min-height: 2.5rem; }
  @media (max-width: 768px) {
    .bam--description-comment {
      min-height: 4rem; } }

.bam--text {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  margin-top: 1vh;
  line-height: 1.2rem;
  letter-spacing: -0.02em;
  color: #343434;
  font-family: "Helvetica"; }

.bam--card-comment {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 18px !important; }

.bam--title-services {
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5px;
  letter-spacing: -0.02em;
  color: #343434; }

.bam--copyright {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #767676;
  position: absolute;
  bottom: 0;
  margin-left: 6vh; }
  @media (max-width: 768px) {
    .bam--copyright {
      position: unset;
      bottom: 0;
      margin-left: 0vh;
      margin-top: 1vh; } }

@media (max-width: 768px) {
  .bam--copyright-img {
    width: 30vh; } }

.bam--title-footer {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  cursor: pointer;
  color: #767676; }

.bam--sub-title-footer {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  cursor: pointer;
  color: #767676; }

.bam--mini-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  cursor: pointer;
  color: #767676; }

.bam--card-home {
  border: 1px solid #aeb5b3;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: #ffffff; }

.bam--card-home .ant-card-body {
  padding: 10px 24px 5px 24px; }

.bam--reservation-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: #343434;
  padding: 4px 11px 4px; }

.bam--reservation-description {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #767676; }

.bam--border-right {
  border-right: 1px solid #aeb5b3; }
  @media (max-width: 768px) {
    .bam--border-right {
      border: 0px; } }

.bam--home-left-margin {
  margin-left: 3vh; }
  @media (max-width: 768px) {
    .bam--home-left-margin {
      margin-left: 0vh; } }

.bam--cgu-article {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline; }

.lst-kix_list_2-6 > li:before {
  content: "\25CF    "; }

.lst-kix_list_2-7 > li:before {
  content: "o  "; }

ul.lst-kix_list_1-0 {
  list-style-type: none; }

.lst-kix_list_2-4 > li:before {
  content: "o  "; }

.lst-kix_list_2-5 > li:before {
  content: "\25AA    "; }

.lst-kix_list_2-8 > li:before {
  content: "\25AA    "; }

.lst-kix_list_3-0 > li:before {
  content: "-  "; }

.lst-kix_list_3-1 > li:before {
  content: "o  "; }

.lst-kix_list_3-2 > li:before {
  content: "\25AA    "; }

ul.lst-kix_list_3-7 {
  list-style-type: none; }

ul.lst-kix_list_3-8 {
  list-style-type: none; }

ul.lst-kix_list_1-3 {
  list-style-type: none; }

ul.lst-kix_list_3-1 {
  list-style-type: none; }

.lst-kix_list_3-5 > li:before {
  content: "\25AA    "; }

ul.lst-kix_list_1-4 {
  list-style-type: none; }

ul.lst-kix_list_3-2 {
  list-style-type: none; }

ul.lst-kix_list_1-1 {
  list-style-type: none; }

.lst-kix_list_3-4 > li:before {
  content: "o  "; }

ul.lst-kix_list_1-2 {
  list-style-type: none; }

ul.lst-kix_list_3-0 {
  list-style-type: none; }

ul.lst-kix_list_1-7 {
  list-style-type: none; }

.lst-kix_list_3-3 > li:before {
  content: "\25CF    "; }

ul.lst-kix_list_3-5 {
  list-style-type: none; }

ul.lst-kix_list_1-8 {
  list-style-type: none; }

ul.lst-kix_list_3-6 {
  list-style-type: none; }

ul.lst-kix_list_1-5 {
  list-style-type: none; }

ul.lst-kix_list_3-3 {
  list-style-type: none; }

ul.lst-kix_list_1-6 {
  list-style-type: none; }

ul.lst-kix_list_3-4 {
  list-style-type: none; }

.lst-kix_list_3-8 > li:before {
  content: "\25AA    "; }

.lst-kix_list_3-6 > li:before {
  content: "\25CF    "; }

.lst-kix_list_3-7 > li:before {
  content: "o  "; }

ul.lst-kix_list_2-8 {
  list-style-type: none; }

ul.lst-kix_list_2-2 {
  list-style-type: none; }

.lst-kix_list_1-0 > li:before {
  content: "\2B9A    "; }

ul.lst-kix_list_2-3 {
  list-style-type: none; }

ul.lst-kix_list_2-0 {
  list-style-type: none; }

ul.lst-kix_list_2-1 {
  list-style-type: none; }

ul.lst-kix_list_2-6 {
  list-style-type: none; }

.lst-kix_list_1-1 > li:before {
  content: "o  "; }

.lst-kix_list_1-2 > li:before {
  content: "\25AA    "; }

ul.lst-kix_list_2-7 {
  list-style-type: none; }

ul.lst-kix_list_2-4 {
  list-style-type: none; }

ul.lst-kix_list_2-5 {
  list-style-type: none; }

.lst-kix_list_1-3 > li:before {
  content: "\25CF    "; }

.lst-kix_list_1-4 > li:before {
  content: "o  "; }

.lst-kix_list_1-7 > li:before {
  content: "o  "; }

.lst-kix_list_1-5 > li:before {
  content: "\25AA    "; }

.lst-kix_list_1-6 > li:before {
  content: "\25CF    "; }

li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt; }

.lst-kix_list_2-0 > li:before {
  content: "\2022    "; }

.lst-kix_list_2-1 > li:before {
  content: "o  "; }

.lst-kix_list_1-8 > li:before {
  content: "\25AA    "; }

.lst-kix_list_2-2 > li:before {
  content: "\25AA    "; }

.lst-kix_list_2-3 > li:before {
  content: "\25CF    "; }

ol {
  margin: 0;
  padding: 0; }

table td,
table th {
  padding: 0; }

.c7 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  font-weight: 700;
  text-decoration: underline;
  vertical-align: baseline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  font-size: 12pt;
  font-family: Helvetica;
  font-style: normal; }

.c5 {
  margin-left: 53.4pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify; }

.c12 {
  margin-left: 88.8pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify; }

.c8 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify; }

.c6 {
  padding-top: 0pt;
  text-indent: 35.4pt;
  padding-bottom: 8pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left; }

.c9 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-family: Helvetica;
  font-style: normal; }

.c3 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16px;
  font-family: Helvetica;
  font-style: normal; }

.c0 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16px;
  font-family: Helvetica;
  font-style: normal; }

.c11 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left; }

.c1 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify; }

.c4 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: center; }

.c18 {
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #0563c1;
  text-decoration: underline; }

.c14 {
  background-color: #ffffff;
  padding: 5pt 20pt 20pt 20pt; }

.c13 {
  margin-left: 88.8pt;
  padding-left: 0pt; }

.c15 {
  margin-left: 36pt;
  padding-left: 0pt; }

.c10 {
  padding: 0;
  margin: 0; }

.c2 {
  margin-left: 35.4pt; }

.c16 {
  height: 16px; }

.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left; }

li {
  color: #000000;
  font-size: 16px;
  font-family: Helvetica; }

p {
  margin: 0;
  color: #000000;
  font-size: 16px;
  font-family: Helvetica; }

h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

h5 {
  padding-top: 16px;
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 2pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: Helvetica;
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

.lst2-kix_list_5-1 > li {
  counter-increment: lst-ctn-kix_list_5-1; }

ul.lst2-kix_list_1-0 {
  list-style-type: none; }

.lst2-kix_list_3-0 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_3-1 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_3-2 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0; }

.lst2-kix_list_5-0 > li {
  counter-increment: lst-ctn-kix_list_5-0; }

ul.lst2-kix_list_1-3 {
  list-style-type: none; }

.lst2-kix_list_3-5 > li:before {
  content: "\25CF    "; }

ul.lst2-kix_list_1-4 {
  list-style-type: none; }

ul.lst2-kix_list_1-1 {
  list-style-type: none; }

.lst2-kix_list_3-4 > li:before {
  content: "\25CF    "; }

ul.lst2-kix_list_1-2 {
  list-style-type: none; }

ul.lst2-kix_list_1-7 {
  list-style-type: none; }

.lst2-kix_list_3-3 > li:before {
  content: "\25CF    "; }

ul.lst2-kix_list_1-8 {
  list-style-type: none; }

ul.lst2-kix_list_1-5 {
  list-style-type: none; }

ul.lst2-kix_list_1-6 {
  list-style-type: none; }

.lst2-kix_list_3-8 > li:before {
  content: "\25CF    "; }

ol.lst2-kix_list_5-3.start {
  counter-reset: lst-ctn-kix_list_5-3 0; }

.lst2-kix_list_3-6 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_4-3 > li {
  counter-increment: lst-ctn-kix_list_4-3; }

.lst2-kix_list_3-7 > li:before {
  content: "\25CF    "; }

ol.lst2-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0; }

ol.lst2-kix_list_5-0.start {
  counter-reset: lst-ctn-kix_list_5-0 0; }

.lst2-kix_list_5-2 > li {
  counter-increment: lst-ctn-kix_list_5-2; }

ol.lst2-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0; }

.lst2-kix_list_5-0 > li:before {
  content: "" counter(lst-ctn-kix_list_5-0, decimal) ". "; }

.lst2-kix_list_5-4 > li {
  counter-increment: lst-ctn-kix_list_5-4; }

.lst2-kix_list_4-4 > li {
  counter-increment: lst-ctn-kix_list_4-4; }

.lst2-kix_list_4-8 > li:before {
  content: "" counter(lst-ctn-kix_list_4-8, decimal) ". "; }

.lst2-kix_list_5-3 > li:before {
  content: "" counter(lst-ctn-kix_list_5-3, decimal) ". "; }

.lst2-kix_list_4-7 > li:before {
  content: "" counter(lst-ctn-kix_list_4-7, decimal) ". "; }

.lst2-kix_list_5-2 > li:before {
  content: "" counter(lst-ctn-kix_list_5-2, decimal) ". "; }

.lst2-kix_list_5-1 > li:before {
  content: "" counter(lst-ctn-kix_list_5-1, decimal) ". "; }

.lst2-kix_list_5-7 > li:before {
  content: "" counter(lst-ctn-kix_list_5-7, decimal) ". "; }

ol.lst2-kix_list_5-6.start {
  counter-reset: lst-ctn-kix_list_5-6 0; }

.lst2-kix_list_5-6 > li:before {
  content: "" counter(lst-ctn-kix_list_5-6, decimal) ". "; }

.lst2-kix_list_5-8 > li:before {
  content: "" counter(lst-ctn-kix_list_5-8, decimal) ". "; }

ol.lst2-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0; }

ul.lst2-kix_list_4-1 {
  list-style-type: none; }

.lst2-kix_list_5-4 > li:before {
  content: "" counter(lst-ctn-kix_list_5-4, decimal) ". "; }

.lst2-kix_list_5-5 > li:before {
  content: "" counter(lst-ctn-kix_list_5-5, decimal) ". "; }

.lst2-kix_list_6-1 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_6-3 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_6-0 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_6-4 > li:before {
  content: "\25CF    "; }

ol.lst2-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0; }

.lst2-kix_list_6-2 > li:before {
  content: "\25CF    "; }

ol.lst2-kix_list_5-5.start {
  counter-reset: lst-ctn-kix_list_5-5 0; }

.lst2-kix_list_6-8 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_6-5 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_6-7 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_7-0 > li:before {
  content: "\25CF    "; }

ol.lst2-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0; }

.lst2-kix_list_6-6 > li:before {
  content: "\25CF    "; }

ol.lst2-kix_list_5-0 {
  list-style-type: none; }

.lst2-kix_list_2-6 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_2-7 > li:before {
  content: "\25CF    "; }

ol.lst2-kix_list_5-1 {
  list-style-type: none; }

ol.lst2-kix_list_5-2 {
  list-style-type: none; }

.lst2-kix_list_7-4 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_7-6 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_2-4 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_2-5 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_2-8 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_7-1 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_7-5 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_7-2 > li:before {
  content: "\25CF    "; }

ol.lst2-kix_list_5-4.start {
  counter-reset: lst-ctn-kix_list_5-4 0; }

.lst2-kix_list_7-3 > li:before {
  content: "\25CF    "; }

ul.lst2-kix_list_7-5 {
  list-style-type: none; }

ul.lst2-kix_list_7-6 {
  list-style-type: none; }

ul.lst2-kix_list_7-3 {
  list-style-type: none; }

ul.lst2-kix_list_3-7 {
  list-style-type: none; }

ul.lst2-kix_list_7-4 {
  list-style-type: none; }

ul.lst2-kix_list_3-8 {
  list-style-type: none; }

ol.lst2-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0; }

ol.lst2-kix_list_5-1.start {
  counter-reset: lst-ctn-kix_list_5-1 0; }

ul.lst2-kix_list_7-7 {
  list-style-type: none; }

ul.lst2-kix_list_7-8 {
  list-style-type: none; }

ol.lst2-kix_list_5-7 {
  list-style-type: none; }

ul.lst2-kix_list_3-1 {
  list-style-type: none; }

ol.lst2-kix_list_5-8 {
  list-style-type: none; }

ul.lst2-kix_list_3-2 {
  list-style-type: none; }

.lst2-kix_list_5-7 > li {
  counter-increment: lst-ctn-kix_list_5-7; }

.lst2-kix_list_7-8 > li:before {
  content: "\25CF    "; }

ul.lst2-kix_list_3-0 {
  list-style-type: none; }

ol.lst2-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0; }

ol.lst2-kix_list_5-3 {
  list-style-type: none; }

ul.lst2-kix_list_7-1 {
  list-style-type: none; }

ul.lst2-kix_list_3-5 {
  list-style-type: none; }

.lst2-kix_list_4-7 > li {
  counter-increment: lst-ctn-kix_list_4-7; }

ol.lst2-kix_list_5-4 {
  list-style-type: none; }

ul.lst2-kix_list_7-2 {
  list-style-type: none; }

ul.lst2-kix_list_3-6 {
  list-style-type: none; }

ol.lst2-kix_list_5-5 {
  list-style-type: none; }

ul.lst2-kix_list_3-3 {
  list-style-type: none; }

ol.lst2-kix_list_5-6 {
  list-style-type: none; }

ul.lst2-kix_list_7-0 {
  list-style-type: none; }

.lst2-kix_list_7-7 > li:before {
  content: "\25CF    "; }

ul.lst2-kix_list_3-4 {
  list-style-type: none; }

.lst2-kix_list_5-8 > li {
  counter-increment: lst-ctn-kix_list_5-8; }

.lst2-kix_list_4-0 > li:before {
  content: "" counter(lst-ctn-kix_list_4-0, decimal) ". "; }

.lst2-kix_list_4-1 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_4-6 > li {
  counter-increment: lst-ctn-kix_list_4-6; }

.lst2-kix_list_4-4 > li:before {
  content: "" counter(lst-ctn-kix_list_4-4, decimal) ". "; }

.lst2-kix_list_4-3 > li:before {
  content: "" counter(lst-ctn-kix_list_4-3, decimal) ". "; }

.lst2-kix_list_4-5 > li:before {
  content: "" counter(lst-ctn-kix_list_4-5, decimal) ". "; }

.lst2-kix_list_4-2 > li:before {
  content: "" counter(lst-ctn-kix_list_4-2, decimal) ". "; }

.lst2-kix_list_4-6 > li:before {
  content: "" counter(lst-ctn-kix_list_4-6, decimal) ". "; }

ol.lst2-kix_list_5-7.start {
  counter-reset: lst-ctn-kix_list_5-7 0; }

.lst2-kix_list_5-5 > li {
  counter-increment: lst-ctn-kix_list_5-5; }

ol.lst2-kix_list_4-0 {
  list-style-type: none; }

ol.lst2-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0; }

ol.lst2-kix_list_4-2 {
  list-style-type: none; }

ol.lst2-kix_list_4-3 {
  list-style-type: none; }

ul.lst2-kix_list_6-6 {
  list-style-type: none; }

ul.lst2-kix_list_6-7 {
  list-style-type: none; }

.lst2-kix_list_5-3 > li {
  counter-increment: lst-ctn-kix_list_5-3; }

ul.lst2-kix_list_6-4 {
  list-style-type: none; }

ul.lst2-kix_list_2-8 {
  list-style-type: none; }

ul.lst2-kix_list_6-5 {
  list-style-type: none; }

ul.lst2-kix_list_6-8 {
  list-style-type: none; }

ol.lst2-kix_list_4-8 {
  list-style-type: none; }

ul.lst2-kix_list_2-2 {
  list-style-type: none; }

.lst2-kix_list_1-0 > li:before {
  content: "\25CF    "; }

ul.lst2-kix_list_2-3 {
  list-style-type: none; }

ul.lst2-kix_list_2-0 {
  list-style-type: none; }

ul.lst2-kix_list_2-1 {
  list-style-type: none; }

ol.lst2-kix_list_4-4 {
  list-style-type: none; }

ul.lst2-kix_list_6-2 {
  list-style-type: none; }

ul.lst2-kix_list_2-6 {
  list-style-type: none; }

ol.lst2-kix_list_4-5 {
  list-style-type: none; }

ul.lst2-kix_list_6-3 {
  list-style-type: none; }

.lst2-kix_list_1-1 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_1-2 > li:before {
  content: "\25CF    "; }

ul.lst2-kix_list_2-7 {
  list-style-type: none; }

ol.lst2-kix_list_4-6 {
  list-style-type: none; }

ul.lst2-kix_list_6-0 {
  list-style-type: none; }

ul.lst2-kix_list_2-4 {
  list-style-type: none; }

ol.lst2-kix_list_4-7 {
  list-style-type: none; }

ul.lst2-kix_list_6-1 {
  list-style-type: none; }

ul.lst2-kix_list_2-5 {
  list-style-type: none; }

.lst2-kix_list_1-3 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_1-4 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_4-8 > li {
  counter-increment: lst-ctn-kix_list_4-8; }

.lst2-kix_list_1-7 > li:before {
  content: "\25CF    "; }

ol.lst2-kix_list_5-8.start {
  counter-reset: lst-ctn-kix_list_5-8 0; }

.lst2-kix_list_1-5 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_1-6 > li:before {
  content: "\25CF    "; }

li.li2-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt; }

.lst2-kix_list_5-6 > li {
  counter-increment: lst-ctn-kix_list_5-6; }

.lst2-kix_list_2-0 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_2-1 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_4-5 > li {
  counter-increment: lst-ctn-kix_list_4-5; }

.lst2-kix_list_1-8 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_2-2 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_2-3 > li:before {
  content: "\25CF    "; }

.lst2-kix_list_4-2 > li {
  counter-increment: lst-ctn-kix_list_4-2; }

ol.lst2-kix_list_5-2.start {
  counter-reset: lst-ctn-kix_list_5-2 0; }

ol {
  margin: 0;
  padding: 0; }

.x21 {
  background-color: #ffffff;
  margin-left: 0pt;
  padding-top: 8.6pt;
  padding-left: 0pt;
  padding-bottom: 8.6pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left; }

.x11 {
  background-color: #ffffff;
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left; }

.x6 {
  background-color: #ffffff;
  margin-left: 36pt;
  padding-top: 8.6pt;
  padding-left: 0pt;
  padding-bottom: 8.6pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left; }

.x15 {
  background-color: #ffffff;
  margin-left: 0pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify; }

.c8 {
  background-color: #ffffff;
  margin-left: 0pt;
  padding-top: 8.6pt;
  padding-left: 0pt;
  padding-bottom: 8.6pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify; }

.x10 {
  background-color: #ffffff;
  margin-left: 0pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left; }

.x3 {
  background-color: #ffffff;
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 16px; }

.x28 {
  background-color: #ffffff;
  padding-top: 8.6pt;
  padding-bottom: 8.6pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 16px; }

.x5 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 16px; }

.c9 {
  background-color: #ffffff;
  padding-bottom: 8.6pt;
  margin-top: 2vh;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 16px; }

.x24 {
  -webkit-text-decoration-skip: none;
  color: #0563x1;
  font-weight: 400;
  text-decoration: underline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  font-size: 16px;
  font-family: "Helvetica Neue"; }

.x25 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 16px; }

.x27 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center; }

.x7 {
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  font-weight: 400;
  text-decoration: underline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  font-size: 16px;
  font-family: "Helvetica Neue"; }

.x12 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: left; }

.x16 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: justify; }

.x1 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16px;
  font-family: "Helvetica Neue";
  font-style: normal; }

.x18 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 20px;
  orphans: 2;
  widows: 2;
  text-align: center; }

.x4 {
  font-size: 16px;
  font-family: "Helvetica Neue";
  font-weight: 700; }

.x22 {
  font-size: 11.5pt;
  font-family: "Helvetica Neue";
  font-weight: 400; }

.x2 {
  font-size: 16px;
  font-family: "Helvetica Neue";
  font-weight: 400; }

.x23 {
  font-size: 16px;
  font-family: "PT Sans";
  font-weight: 400; }

.x14 {
  font-weight: 400;
  font-size: 16px;
  font-family: "Helvetica"; }

.x26 {
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  font-family: "Helvetica Neue"; }

.x17 {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  font-family: "Helvetica Neue"; }

.x19 {
  background-color: #ffffff;
  max-width: 453.6pt;
  padding: 70.8pt 70.8pt 70.8pt 70.8pt; }

.x13 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal; }

.x20 {
  color: inherit;
  text-decoration: inherit; }

.c0 {
  padding: 0;
  margin: 0; }

.x30 {
  height: 16px; }

.x29 {
  margin-left: 72pt; }

.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 20px;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left; }

li {
  color: #000000;
  font-size: 16px;
  font-family: "Helvetica"; }

h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

h5 {
  padding-top: 16px;
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 2pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Helvetica";
  line-height: 20px;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

.bam--home-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #343434; }
  @media (max-width: 900px) {
    .bam--home-title {
      font-size: 25px; } }

.bam--modal-big {
  border-radius: 18px !important; }

.bam--modal-big .ant-modal-body {
  padding: 0px !important; }

.bam--input {
  background: #ffffff;
  border: 1px solid #767676;
  box-sizing: border-box;
  border-radius: 6px;
  height: 48px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #767676; }

.bam--input-number {
  border-radius: 6px; }

.bam--input-number .ant-input-number-input {
  background: #ffffff;
  border: 1px solid #767676;
  box-sizing: border-box;
  border-radius: 6px;
  height: 48px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #767676; }

.bam--label-form {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #343434; }

.bam--button-carrousel {
  background-color: #52c99f;
  border-radius: 6px;
  height: 40px;
  width: 40px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer; }

.bam--button-carrousel-text {
  background-color: #52c99f;
  border-radius: 6px;
  height: 40px;
  padding: 1vh;
  min-width: 12vh;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff; }
  @media (max-width: 768px) {
    .bam--button-carrousel-text {
      font-size: 14px;
      height: 30px;
      line-height: 10px; } }

.bam--button-danger {
  background-color: #ff4d4f;
  border-radius: 6px;
  height: 40px;
  padding: 1vh;
  min-width: 12vh;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff; }
  @media (max-width: 768px) {
    .bam--button-danger {
      font-size: 14px;
      height: 30px;
      line-height: 10px; } }

.bam--button-logout {
  background-color: #ff4d4f;
  border-radius: 6px;
  height: 40px;
  padding: 1vh;
  min-width: 12vh;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff; }
  @media (max-width: 768px) {
    .bam--button-logout {
      font-size: 14px;
      height: 30px;
      line-height: 10px; } }

.bam--dot {
  background: #c4c4c4;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 2vh; }

.bam--dot-active {
  border-radius: 50%;
  background: #52c99f;
  margin-right: 2vh;
  height: 8px;
  width: 8px; }

.slick-slide img {
  display: inline;
  display: initial; }

.ant-radio-inner::after {
  background-color: #4ba48e !important; }

.ant-radio-wrapper span {
  font-family: Helvetica;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #343434 !important; }

.bam--carroussel-item {
  height: 100%;
  flex: 1 1; }

.bam--img-becamepro {
  border-radius: 0px 18px 0px 0px; }

.bam--hr-became {
  border: 1px solid #e1e1e1; }

.bam--text-time {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #343434; }

.bam--profile-time {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 2vh;
  text-align: center;
  color: #343434; }

.bam--profile-price {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  /* Black black */
  color: #343434; }

.bam--profile-select {
  background: #ffffff;
  /* Black black */
  border: 1px solid #343434;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  min-height: 5vh;
  min-width: 18vh !important;
  color: #343434; }
  @media (max-width: 900px) {
    .bam--profile-select {
      max-width: 5vh !important;
      min-width: 15vh !important;
      font-size: 12px; } }

.bam--marker-map {
  background-image: url(/static/media/marker.8a7fb0fb.svg);
  color: #fff;
  text-align: center;
  background-repeat: no-repeat; }

.bam--pro-background {
  background: rgba(253, 172, 151, 0.7); }

.bam--content {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
  @media (max-width: 1100px) {
    .bam--content {
      width: 100%; } }

.bam--pro-title1 {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 60px;
  color: #343434;
  text-align: center;
  margin-left: 5px;
  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08); }
  @media (max-width: 900px) {
    .bam--pro-title1 {
      font-size: 1.2rem;
      margin-left: 10px;
      line-height: 20px; } }

.bam--pro-title2 {
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  color: #343434;
  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  margin-left: 5px;
  text-align: center; }
  @media (max-width: 900px) {
    .bam--pro-title2 {
      font-size: 1rem;
      margin-left: 10px; } }

.bam--pro-description {
  font-style: normal;
  font-weight: 400;
  font-size: 1.35rem;
  line-height: 1.25rem;
  color: #343434;
  margin-left: 5px;
  text-align: center; }
  @media (max-width: 900px) {
    .bam--pro-description {
      margin-left: 30px;
      margin-right: 30px;
      font-size: 1rem; } }

.bam--pro-bt {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  letter-spacing: -0.01em;
  height: 48px;
  color: #ffffff;
  margin-left: 5px;
  background: #f87d6b;
  border-radius: 6px; }

.bam--pro-border-width {
  width: 80%;
  margin-left: 20%; }

.bam--slider-about {
  background-image: url(/static/media/about.ba593c22.svg);
  background-size: contain;
  position: relative;
  background-repeat: no-repeat;
  height: 50vh;
  width: 100%;
  background-position: bottom right;
  background-color: #fb815e; }
  @media (max-width: 768px) {
    .bam--slider-about {
      height: 60vh; } }

.bam--slider-about-title {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2rem;
  color: #ffffff;
  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08); }
  @media (max-width: 768px) {
    .bam--slider-about-title {
      font-size: 1rem;
      line-height: 1rem; } }

.bam--title-valeur {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #4ba48e; }

.bam--paragraph-valeur {
  margin-top: 2vh;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;
  color: #343434; }

.bam--mission {
  background-image: url(/static/media/mission-back.82a3a03a.svg);
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  width: 100%; }

.bam--title-mission {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.25rem;
  letter-spacing: -0.02em;
  color: #ffffff; }

.bam--paragraph-mission {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2rem;
  margin-top: 2vh;
  /* or 107% */
  letter-spacing: -0.02em;
  /* White White */
  color: #ffffff;
  text-shadow: 0px 4px 18px rgba(0, 0, 0, 0.08); }

.bam--cgu-paragraph {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  /* Black black */
  color: #343434; }

.bam--cgu-title-description {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #343434; }

.bam--cgu-description {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-right: 2vh;
  color: #767676; }

.bam--faq-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: -0.02em;
  color: #343434; }
  @media (max-width: 768px) {
    .bam--faq-title {
      font-size: 0.8rem; } }

.bam--faq-paragraph {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #767676;
  border-left: 2px solid #4ba48e;
  padding-left: 20px;
  margin-left: 3vh;
  width: 90%; }

.bam--faq-hr {
  border-top: 1px solid rgba(174, 181, 179, 0.4); }

@media (max-width: 768px) {
  .bam--margin-cgu {
    margin-top: 4vh; } }

.bam--bampro-patagraph {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 30px;
  text-align: justify;
  color: #000000;
  margin: 0.5vh;
  margin-left: 6vh; }
  @media (max-width: 900px) {
    .bam--bampro-patagraph {
      font-size: 1.2rem;
      margin-left: 30px;
      margin-right: 30px; } }

.bam--bampro-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000; }
  @media (max-width: 900px) {
    .bam--bampro-title {
      font-size: 1.5rem;
      margin-left: 10px; } }

.bam--bampro-quote {
  font-family: Helvetica;
  font-weight: 600;
  font-size: 2rem;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 4vh;
  color: #ffffff; }
  @media (max-width: 900px) {
    .bam--bampro-quote {
      font-size: 1.5rem;
      margin-left: 10px; } }

.bam--bampro-title-section {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 32px;
  align-items: center;
  text-align: center;
  font-family: Helvetica;
  letter-spacing: 0.1em; }

.bam--bampro-paragraph-section {
  font-family: Helvetica;
  font-style: normal;
  text-align: justify;
  font-weight: 400;
  font-size: 1rem;
  line-height: 25px;
  margin-top: 1vh;
  display: flex;
  align-items: center;
  color: #000000;
  justify-content: center;
  margin: 25px; }

.bam--button-pro {
  height: 50px;
  background: #f87d6b;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.01em;
  font-family: Helvetica;
  color: #ffffff; }

.bam--green-text {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #4ba48e;
  font-family: Helvetica; }
  @media (max-width: 900px) {
    .bam--green-text {
      font-size: 20px; } }

.bam--text-contact {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #000000; }
  @media (max-width: 900px) {
    .bam--text-contact {
      font-size: 15px; } }

.bam--title-faq {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #343434;
  margin-top: 10px; }
  @media (max-width: 900px) {
    .bam--title-faq {
      font-size: 20px; } }

.bam--pro-prix-border {
  border: 20px solid #ffbba6ff;
  border-radius: 35px;
  justify-content: center; }
  @media (max-width: 900px) {
    .bam--pro-prix-border {
      margin-left: 30px;
      margin-right: 30px; } }
  @media (max-width: 500px) {
    .bam--pro-prix-border {
      margin-left: 5px;
      margin-right: 5px; } }

.bam--pro-prix-title {
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000; }
  @media (max-width: 900px) {
    .bam--pro-prix-title {
      font-size: 0.85rem; } }
  @media (max-width: 500px) {
    .bam--pro-prix-title {
      font-size: 0.95rem; } }

.bam--pro-ul li {
  padding: 1vh;
  list-style: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAA4WAAAOFgF50xSiAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAA0hJREFUOI2VlO1TE1cYxX+72YRACIUIHYIlmigEogV0AIFOaW3/505nasepQ6HF8mIt2oRaMDYgRGNI2M0r2X442USlX/rM7OzM3XvPnuec81zDrZRdPi738tKlMoz/3GtdAnJdaDTAccB2oF7Xms8HwSCE+/U2TZ1pXkDFgVAITPM9QA8odwS7v8PhSygWoVbTN8unQ6Oj8M3XEBvXt60dKJXh/ioEg1gdypUqpNPw3fdwbkOwBwY/geEr4A9AuQRv30EuB4V3MDQEaz9DOgPf3odAAAyjzbBaha1tePAjWBbMzcDsDFy/LmCAiwsxth2xXV+Hp3/AnTlITnYkEODBIaytg+WHL1dgbg4Gwl2dQBpGItqz8Ss8eQqJOCzOQ09PxySTZhM2NsE+h/m7sLKsVg3jQwddF2wbNjfh8W8QDsPCvHQvlaDVAtfFJJ+H/QyMjcHyPfD7JUE+Dyenctt1wamoxa0dMA21Gg7Dw59EqN5ot/xnBlxgYgL6+wWWzsDeM/11egombsJhFh5vQ7ksZskJ6Z19Be4rWLkHPQEsckfS4NMR6VSp6tBpHo5fw1kJ3hYUo6MjuJmAu3fkMsDgIGSzMg2wcBzwW103+/pgdhZ6e+HhI/gnp9brdUVocQGio13DQiFoNKHltk3x+4XebMoInymHk0m4fUsHbVs5u5WCWEytetWoa097Ek2Gh6FWh7MzaeZVXy/Er8HIsA5ci0FqWqPnzbHjQKGorny+NuCNuBi+zEK11gU0TbX42VWIRhX2sWjnIK0W/PUCSmf6aZu1SWwcRkbg7wPI7HezZxiKRWoaVr+AyUm17VWhADu70i8eV9wAk1AIlhY1Uo/WYP9F91AgADcS0jLc3123beXx4BCuDEFqSgwNAwvDgNspWb/3HH54AI4NiQSE+hQpr2p1Mdvegd0nujS+WlXk2roarlNyabXg+FjzvPdMUYjF4GoUBgbAZ0GlAm/eSOuTUxgcgOUlmPlcEesAVsouriuRi0V4noaNXxRmvyUWpqGLtF4X06kkLC3A+LguW8/1DqA3/NAd9tcnYnJ+DhctBT8SkdORCPS2b+33wD4E9MoDbt8enccw9HggHwF5ZV1a8TZ6efuf9S/F31eMH6U4GQAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: left center; }

.bam--pro-ul {
  -webkit-padding-start: 30px !important;
          padding-inline-start: 30px !important; }
  @media (max-width: 900px) {
    .bam--pro-ul {
      -webkit-padding-start: 20px !important;
              padding-inline-start: 20px !important; } }
  @media (max-width: 500px) {
    .bam--pro-ul {
      -webkit-padding-start: 10px !important;
              padding-inline-start: 10px !important; } }

.bam--profile-name {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #343434; }
  @media (max-width: 768px) {
    .bam--profile-name {
      font-size: 15px;
      line-height: 20px; } }

.bam--profile-rate {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  display: inline-flex;
  letter-spacing: -0.02em;
  color: #f7b003; }
  @media (max-width: 768px) {
    .bam--profile-rate {
      font-size: 20px;
      line-height: 5px; } }

.bam--profile-rate img {
  padding-top: -20px; }
  @media (max-width: 768px) {
    .bam--profile-rate img {
      height: 15px; } }

.bam--horaire {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  /* or 107% */
  letter-spacing: -0.02em; }
  @media (max-width: 768px) {
    .bam--horaire {
      font-size: 12px;
      line-height: 25px; } }

.bam--profile-nb-rate {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #4ba48e;
  vertical-align: text-bottom; }
  @media (max-width: 768px) {
    .bam--profile-nb-rate {
      font-size: 10px;
      vertical-align: bottom; } }

.bam--no-border-bottom {
  border-bottom: 1px transparent solid !important; }

.bam--border-bottom {
  border-bottom: 1px solid #aeb5b3; }

.bam--like-text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #4ba48e; }

.bam--profile-tabs .ant-tabs-tab-active {
  background: #c6f0e6;
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #4ba48e !important; }

.bam--profile-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  min-width: 20vh;
  color: #343434;
  text-align: left; }
  @media (max-width: 768px) {
    .bam--profile-tabs .ant-tabs-tab .ant-tabs-tab-btn {
      min-width: 12vh; } }

.bam--service-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #343434; }

.bam--profile-address {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #4ba48e; }
  @media (max-width: 768px) {
    .bam--profile-address {
      font-size: 12px; } }

.bam--avis-text-bold {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */
  /* Black black */
  color: #343434; }

.bam--nb-results {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #767676; }
  @media (max-width: 768px) {
    .bam--nb-results {
      margin-left: 5px; } }

.bam--margin-profil {
  margin-top: 4vh; }
  @media (max-width: 900px) {
    .bam--margin-profil {
      margin-top: 0vh; } }

.bam--margin-avis {
  margin-top: 0.5vh; }
  @media (max-width: 900px) {
    .bam--margin-avis {
      margin-top: 0.5vh; } }

.bam--modal-profil .ant-modal-header {
  border-radius: 18px 18px 0px 0px; }

.ant-input-wrapper .ant-input-group {
  height: 54px;
  box-sizing: border-box;
  border-radius: 28px; }

ul.bam--ul-text {
  list-style-type: circle; }

.bam--hide-text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */
  text-align: right;
  letter-spacing: -0.02em;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  margin-right: 2vh;
  color: #767676; }

.bam--img-results {
  height: 100%;
  width: 100%;
  border-radius: 10px 0px 0px 10px; }
  @media (min-width: 1080px) {
    .bam--img-results {
      max-height: 320px; } }

.bam--results-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #343434; }

.bam--results-description {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #767676; }

.bam--results-price {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #767676; }

.circle-red:before {
  content: " \25CF";
  font-size: 15px;
  color: red; }

.circle-green:before {
  content: " \25CF";
  font-size: 15px;
  color: #4ba48e; }

.bam--search-input {
  border-radius: 28px;
  min-height: 50px;
  width: 100%;
  border: 1px solid #aeb5b3;
  box-sizing: border-box; }

.bam--search-icon {
  content: url(/static/media/filter.3d759f63.svg);
  margin-top: 5px;
  border-left: 1px solid rgba(174, 181, 179, 0.4); }

.bam--button-choice {
  height: 30px;
  background-color: #aeb5b3;
  border-radius: 28px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-right: 2vh;
  color: #ffffff; }

.bam--item-result {
  padding: 2vh 0vh 2vh 0vh;
  border-bottom: 1px solid #e5e5e5; }

.bam--item-result-parent {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-top: 2vh; }

.bam--item-result-parent:hover {
  box-shadow: 0px 4px 30px rgba(43, 43, 43, 0.13);
  cursor: pointer; }

#rounded div {
  background-color: transparent !important; }

.bam--profile-images-item {
  width: 100%; }

.bam--container-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #ffffff;
  /* Black black */
  height: 48px;
  border: 1px solid #343434;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  /* Black black */
  color: #343434; }

.bam--container-img {
  max-width: 100%;
  max-height: 154px;
  overflow: hidden; }

.bam--container-img img {
  max-width: none;
  max-width: initial;
  /* Maybe optional. See note below */
  max-height: none;
  max-height: initial; }

.bam--img-star {
  width: 10px; }

#slider .slick-dots {
  bottom: 0px !important; }

.bam--espace-pro-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  /* or 107% */
  letter-spacing: -0.02em;
  color: #000000; }

.bam--avis-img {
  width: 62px;
  height: 62px;
  margin-right: 2vh; }

.bam--avis-name {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  display: "block";
  /* identical to box height */
  /* Black black */
  color: #343434; }

.bam--avis-date {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* Grey Dark */
  color: #767676; }

.bam--avis-descrption {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-top: 3vh;
  color: #343434; }

.bam--nouveau--inscription {
  height: 20px;
  left: 370px;
  top: 166px;
  /* Body Helvetica Reg 16 */
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  color: #000000; }

.bam--info--login {
  width: 179px;
  height: 14px;
  left: 370px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* Black black */
  color: #343434; }

.bam--login--button {
  height: 48px;
  background: linear-gradient(180deg, #51c89e 0%, #58d1a6 100%);
  border-radius: 6px; }

.bam--login--text {
  display: inline-block;
  /* position: absolute; */
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  color: #ffffff; }

.bam--forget--password {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: right;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #51c89e; }

.bam--lien--nouveau--inscription {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-left: 2px;
  line-height: 18px;
  text-align: right;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #51c89e; }

.bam--connect--avec {
  margin-left: 11px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000; }

.bam--login--info {
  padding-top: 30px;
  width: 179px;
  height: 14px;
  left: 370px;
  top: 213px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* Black black */
  color: #343434; }

.bam--facebook--button {
  background-color: white;
  color: #4267b2;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 8px 0 2px;
  font-size: 1em;
  line-height: 1;
  border: 1px solid #4267b2;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 17vh; }
  @media (max-width: 768px) {
    .bam--facebook--button {
      width: 90%; } }

.bam--apple--auth--btn {
  height: 40px;
  margin-left: 20px;
  width: 80%;
  text-transform: uppercase;
  font-weight: bold; }

.bam--google--auth--btn {
  background-color: white !important;
  color: #92918d !important;
  font-weight: 700 !important;
  font-size: 1em !important;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px !important;
  padding: 0 8px 0 2px !important;
  line-height: 1 !important;
  border: 1px solid #92918d !important;
  overflow: hidden !important;
  width: 17vh;
  justify-content: center;
  align-items: center;
  height: 40px !important; }
  @media (max-width: 768px) {
    .bam--google--auth--btn {
      width: 90%; } }

.bam--google--auth--btn span {
  font-weight: bold !important; }

.bam--hr-login {
  border: 1px solid #aeb5b3; }

.bam--facebook--button svg {
  margin-right: 10px; }

body {
  font-family: "Helvetica" !important; }

.ant-layout {
  background-color: #fff !important; }

.ant-layout-footer {
  background-color: #f5f5f5 !important; }

.ant-modal-content {
  border-radius: 18px !important; }

.ant-modal-mask {
  background-color: rgba(52, 52, 52, 0.82); }

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: 0px !important; }

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  background: #fff !important; }

.ant-tabs-tab {
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #343434 !important; }

.bam--becamepro-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
  background: #52c99f;
  width: 34px;
  height: 34px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 50%;
  color: white;
  padding: 8px 3px 0 3px;
  text-align: center; }

.slick-dots li.slick-active button:before {
  color: #52c99f !important; }

.slick-dots li button:before {
  width: 8px !important;
  height: 8px !important; }

.ant-menu-horizontal {
  border-bottom: 1px solid transparent; }

.ant-layout-header .ant-menu {
  line-height: 60px !important; }

body::-webkit-scrollbar {
  width: 0.5em; }

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

body::-webkit-scrollbar-thumb {
  background-color: darkgrey; }

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid rgba(174, 181, 179, 0.4); }

.ant-collapse-borderless {
  background-color: #fff !important; }

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border: 0px !important; }

.bam--cursor {
  cursor: pointer; }

.ant-input-wrapper .ant-input-group {
  border: 1px solid #aeb5b3 !important;
  box-sizing: border-box;
  border-radius: 28px !important; }

.dzu-dropzone {
  overflow: hidden !important; }

.ant-switch-checked {
  background-color: #52c99f; }

.ant-tabs-tab {
  font-size: 1rem !important; }

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f87d6b;
  font-weight: 600;
  font-size: 1.25rem; }

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 35px !important; }

.ant-select-selector {
  border: 0px solid transparent !important; }

.bam--card-home .ant-input {
  border: 0px solid transparent !important; }

.ant-input {
  font-family: "Helvetica"; }

.ant-tree-select {
  font-family: "Helvetica"; }

.bam--card-home .ant-picker {
  border: 0px solid transparent !important; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

