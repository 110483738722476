.bam--connect--avec {
  margin-left: 11px;

  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}
.bam--login--info {
  padding-top: 30px;
  width: 179px;
  height: 14px;
  left: 370px;
  top: 213px;

  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  /* Black black */

  color: #343434;
}
.bam--facebook--button {
  background-color: white;
  color: #4267b2;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 8px 0 2px;
  font-size: 1em;
  line-height: 1;
  border: 1px solid #4267b2;
  overflow: hidden;

  justify-content: center;
  align-items: center;
  height: 40px;
  width: 17vh;
  @media (max-width: 768px) {
    width: 90%;
  }
}
.bam--apple--auth--btn {
  height: 40px;
  margin-left: 20px;
  width: 80%;
  text-transform: uppercase;
  font-weight: bold;
}

.bam--google--auth--btn {
  background-color: white !important;
  color: #92918d !important;
  font-weight: 700 !important;
  font-size: 1em !important;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px !important;
  padding: 0 8px 0 2px !important;

  line-height: 1 !important;
  border: 1px solid #92918d !important;
  overflow: hidden !important;
  width: 17vh;
  justify-content: center;
  align-items: center;
  height: 40px !important;
  @media (max-width: 768px) {
    width: 90%;
  }
}
.bam--google--auth--btn span {
  font-weight: bold !important;
}
.bam--hr-login {
  border: 1px solid #aeb5b3;
}
.bam--facebook--button svg {
  margin-right: 10px;
}
