.bam--nouveau--inscription {
  height: 20px;
  left: 370px;
  top: 166px;

  /* Body Helvetica Reg 16 */

  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  color: #000000;
}

.bam--info--login {
  width: 179px;
  height: 14px;
  left: 370px;

  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  /* Black black */

  color: #343434;
}

.bam--login--button {
  height: 48px;
  background: linear-gradient(180deg, #51c89e 0%, #58d1a6 100%);
  border-radius: 6px;
}
.bam--login--text {
  display: inline-block;
  /* position: absolute; */

  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  color: #ffffff;
}

.bam--forget--password {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: right;
  text-decoration-line: underline;
  color: #51c89e;
}
.bam--lien--nouveau--inscription {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-left: 2px;
  line-height: 18px;
  text-align: right;
  text-decoration-line: underline;
  color: #51c89e;
}
