.bam--left--layout {
  background: #4ba48e;
}
.bam--header--pro {
  padding: 0;
  background-color: red !important;
}
.bam--left--layout .ant-menu-item {
  font-family: Helvetica Neue;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 44px !important;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.bam--left--layout li.ant-menu-item.ant-menu-item-selected {
  font-family: Helvetica Neue;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 44px !important;
  letter-spacing: 0.02em;
  color: #ffffff !important;
  background: rgba(29, 64, 56, 0.6) !important;
  opacity: 0.8;
  border-radius: 4px;
}
