@import "./colors.scss";
@import "./menu.scss";
@import "./home.scss";
@import "./becamepro.scss";
@import "./about.scss";
@import "./profile.scss";
@import "./avis.scss";

@import "./modal.scss";
@import "./login.scss";
@import "./social-auth.scss";

body {
  font-family: "Helvetica" !important;
}
.ant-layout {
  background-color: #fff !important;
}
.ant-layout-footer {
  background-color: #f5f5f5 !important;
}
.ant-modal-content {
  border-radius: 18px !important;
}
.ant-modal-mask {
  background-color: rgba(52, 52, 52, 0.82);
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: 0px !important;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  //border: 0px !important;
  background: #fff !important;
}
.ant-tabs-tab {
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #343434 !important;
}
.bam--becamepro-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
  background: #52c99f;
  width: 34px;
  height: 34px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 50%;
  color: white;

  padding: 8px 3px 0 3px;
  text-align: center;
}
.slick-dots li.slick-active button:before {
  color: #52c99f !important;
}
.slick-dots li button:before {
  width: 8px !important;
  height: 8px !important;
}
.ant-menu-horizontal {
  border-bottom: 1px solid transparent;
}
.ant-layout-header .ant-menu {
  line-height: 60px !important;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid rgba(174, 181, 179, 0.4);
}
.ant-collapse-borderless {
  background-color: #fff !important;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border: 0px !important;
}

.bam--cursor {
  cursor: pointer;
}
.ant-input-wrapper .ant-input-group {
  border: 1px solid #aeb5b3 !important;
  box-sizing: border-box;
  border-radius: 28px !important;
}
.dzu-dropzone {
  overflow: hidden !important;
}
.ant-switch-checked {
  background-color: #52c99f;
}
.ant-tabs-tab {
  font-size: 1rem !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $bam--primary-color;
  font-weight: 600;
  font-size: 1.25rem;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 35px !important;
}
.ant-select-selector {
  border: 0px solid transparent !important;
}
.bam--card-home .ant-input {
  border: 0px solid transparent !important;
}
.ant-input {
  font-family: "Helvetica";
}
.ant-tree-select {
  font-family: "Helvetica";
}
.bam--card-home .ant-picker {
  border: 0px solid transparent !important;
}
