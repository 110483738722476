.bam--slider-about {
  background-image: url("./images/about.svg");
  background-size: contain;
  position: relative;
  background-repeat: no-repeat;
  height: 50vh;
  width: 100%;
  background-position: bottom right;
  background-color: #fb815e;
  @media (max-width: 768px) {
    height: 60vh;
  }
}

.bam--slider-about-title {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2rem;
  color: #ffffff;
  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1rem;
  }
}
.bam--title-valeur {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #4ba48e;
}
.bam--paragraph-valeur {
  margin-top: 2vh;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;

  color: #343434;
}
.bam--mission {
  background-image: url("./images/mission-back.svg");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
}
.bam--title-mission {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.25rem;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.bam--paragraph-mission {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2rem;
  margin-top: 2vh;
  /* or 107% */

  letter-spacing: -0.02em;

  /* White White */

  color: #ffffff;

  text-shadow: 0px 4px 18px rgba(0, 0, 0, 0.08);
}

.bam--cgu-paragraph {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  /* Black black */

  color: #343434;
}
.bam--cgu-title-description {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #343434;
}
.bam--cgu-description {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-right: 2vh;
  color: #767676;
}
.bam--faq-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: -0.02em;
  color: #343434;
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
}
.bam--faq-paragraph {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #767676;
  border-left: 2px solid #4ba48e;
  padding-left: 20px;
  margin-left: 3vh;
  width: 90%;
}
.bam--faq-hr {
  border-top: 1px solid rgba(174, 181, 179, 0.4);
}
.bam--margin-cgu {
  @media (max-width: 768px) {
    margin-top: 4vh;
  }
}

.bam--bampro-patagraph {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 30px;
  text-align: justify;
  color: #000000;
  margin: 0.5vh;
  margin-left: 6vh;
  @media (max-width: 900px) {
    font-size: 1.2rem;
    margin-left: 30px;
    margin-right: 30px;
  }
}
.bam--bampro-title {
  font-family: Helvetica;

  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  @media (max-width: 900px) {
    font-size: 1.5rem;
    margin-left: 10px;
  }
}
.bam--bampro-quote {
  font-family: Helvetica;
  font-weight: 600;
  font-size: 2rem;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 4vh;
  color: #ffffff;
  @media (max-width: 900px) {
    font-size: 1.5rem;
    margin-left: 10px;
  }
}
.bam--bampro-title-section {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 32px;
  align-items: center;
  text-align: center;
  font-family: Helvetica;
  letter-spacing: 0.1em;
}
.bam--bampro-paragraph-section {
  font-family: Helvetica;
  font-style: normal;
  text-align: justify;
  font-weight: 400;
  font-size: 1rem;
  line-height: 25px;
  margin-top: 1vh;
  display: flex;
  align-items: center;
  color: #000000;
  justify-content: center;
  margin: 25px;
}
.bam--button-pro {
  height: 50px;
  background: #f87d6b;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.01em;
  font-family: Helvetica;
  color: #ffffff;
}
.bam--green-text {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #4ba48e;
  font-family: Helvetica;
  @media (max-width: 900px) {
    font-size: 20px;
  }
}
.bam--text-contact {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;

  text-align: center;

  color: #000000;
  @media (max-width: 900px) {
    font-size: 15px;
  }
}
.bam--title-faq {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #343434;
  margin-top: 10px;
  @media (max-width: 900px) {
    font-size: 20px;
  }
}

.bam--pro-prix-border {
  border: 20px solid #ffbba6ff;
  border-radius: 35px;
  justify-content: center;
  @media (max-width: 900px) {
    margin-left: 30px;
    margin-right: 30px;
  }
  @media (max-width: 500px) {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.bam--pro-prix-title {
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
  letter-spacing: -0.02em;

  color: #000000;
  @media (max-width: 900px) {
    font-size: 0.85rem;
  }
  @media (max-width: 500px) {
    font-size: 0.95rem;
  }
}
.bam--pro-ul li {
  padding: 1vh;
  list-style: none;
  background-image: url("./images/image.png");
  background-repeat: no-repeat;
  background-position: left center;
}

.bam--pro-ul {
  padding-inline-start: 30px !important;

  @media (max-width: 900px) {
    padding-inline-start: 20px !important;
  }
  @media (max-width: 500px) {
    padding-inline-start: 10px !important;
  }
}
